import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import Topbar from "./Topbar";
import excelimg from "../images/excel.png";
import { Link, useParams } from "react-router-dom";
import "../css/purchaseorder.css";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";
import { branchnamecode } from "./Branchnamecode";
import { Form } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import "../css/pagination.css";
import AccessDenied from "../AccessDenied";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { requestOptionsGet } from "./requestOptionHeaders";
import { baseURL } from "./BaseUrl";
import ViewPurchaseOrderDetails from "./ViewPurchaseOrderDetails";

function Purchaseorder() {
  const { vendorid } = useParams();
  const [purchasedata, setPurchasedata] = useState([]);
  const [viewdetails, setViewdetails] = useState(true);
  const [viewpurchasedata, setViewpurchasedata] = useState("");
  const [searchval, setSearchval] = useState("");
  const [department, setDepartment] = useState({});
  const [branch, setBranch] = useState({});
  const currentDate = new Date();
  const oneMonthAgo = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - 30
  );
  const [fromDate, setFromDate] = useState(oneMonthAgo);
  const [toDate, setToDate] = useState(new Date()); 
  const [isMenuOpen, setIsMenuOpen] = useState({
    style: "menu active",
    menuStatus: "open",
  });
  const [pagewrap, setPageWrap] = useState({
    style: "",
  });

  const viewComponent = (e) => {
    setViewdetails(!viewdetails);
    setViewpurchasedata(e);
  };

  const goBacktopurchaseorder = (e) => {
    setViewdetails(!viewdetails);
  };

  // PAGINATION START
  const PageSize = 20;
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalemployee, setTotalemployee] = useState(0);
  const [pageDataSize, setPageDataSize] = useState(0);
  const [itemOffset, setItemOffset] = useState(1); 

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };

  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // console.log("From:", pageDataFrom, "TO", pageDataTill);
  // PAGINATION END

  // DOWNLOAD EXCEL-FORMATE START
  const fileName = "Purchaseorder Details";
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const Heading = [
    {
      pk_po_DocEntry: "PO Doc.Entry",
      po_Docnum: "PO Doc.No.",
      po_date: "Date",
      po_DueDate: "Due Date",
      vendor_ref_no: "Vendor Ref.No.",
      branch_name: "Branch",
      vendor_name: "Vendor Name",
      shipto: "Ship To",
      billto: "Bill To",
      tax_amount: "Tax Amount",
      doc_total: "Doc. Total",
      payment_days: "Payment Days",
    },
  ];

  const exportExcel = () => {
    const newPOData = purchasedata.map((data) => {
      return {
        pk_po_DocEntry: data.pk_po_DocEntry,
        po_Docnum: data.po_Docnum,
        po_date: moment(data.po_date).format("DD/MM/YYYY"),
        po_DueDate: moment(data.po_DueDate).format("DD/MM/YYYY"),
        vendor_ref_no: data.vendor_ref_no,
        branch_name: data.branch_name[0],
        vendor_name: data.vendor_name[0],
        shipto: data.shipto,
        billto: data.billto,
        tax_amount: data.tax_amount,
        doc_total: data.doc_total,
        payment_days: data.payment_days,
      };
    });

    const ws = XLSX.utils.json_to_sheet(Heading, {
      header: [
        "pk_po_DocEntry",
        "po_Docnum",
        "po_date",
        "po_DueDate",
        "vendor_ref_no",
        "branch_name",
        "vendor_name",
        "shipto",
        "billto",
        "tax_amount",
        "doc_total",
        "payment_days",
      ],
      skipHeader: true,
      origin: 0,
    });
    XLSX.utils.sheet_add_json(ws, newPOData, {
      header: [
        "pk_po_DocEntry",
        "po_Docnum",
        "po_date",
        "po_DueDate",
        "vendor_ref_no",
        "branch_name",
        "vendor_name",
        "shipto",
        "billto",
        "tax_amount",
        "doc_total",
        "payment_days",
      ],
      skipHeader: true,
      origin: -1,
    });

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  // DOWNLOAD EXCEL-FORMATE END

  let userType = sessionStorage.getItem("loginType");
  let vendorCode = sessionStorage.getItem("login-code");

  useEffect(() => {
    fetchpurchasedata();
  }, [currentPage, PageSize, fromDate, toDate, searchval]);

  const fetchpurchasedata = () => {
    let requestOption = requestOptionsGet;
    if (userType === "vendor" || userType === "Vendor") {
      fetch(
        `${baseURL}/purchase_orderjson/get_selected_item/po_based_on_vendor_code/?start_date=${moment(
          fromDate
        ).format("YYYY-MM-DD")}&end_date=${moment(toDate).format(
          "YYYY-MM-DD"
        )}&fk_vendor_code=${vendorCode}&search=${searchval}`,
        requestOption
      )
        .then((res) => res.json())
        .then((res) => {          
          setPurchasedata(res);
        })
        .catch((error) => console.error("ERROR:", error));
    } else if (vendorid) {
      fetch(
        `${baseURL}/purchase_orderjson/get_selected_item/po_based_on_vendor_code/?start_date=${moment(
          fromDate
        ).format("YYYY-MM-DD")}&end_date=${moment(toDate).format(
          "YYYY-MM-DD"
        )}&fk_vendor_code=${vendorid}&search=${searchval}`,
        requestOption
      )
        .then((res) => res.json())
        .then((res) => {         
          setPurchasedata(res);
        })
        .catch((error) => console.error("ERROR:", error));
    } else {
      fetch(
        `${baseURL}/purchase_orderjson/pages?start_date=${moment(
          fromDate
        ).format("YYYY-MM-DD")}&end_date=${moment(toDate).format(
          "YYYY-MM-DD"
        )}&search=${searchval}&page_num=${currentPage}&page_size=${PageSize}`,
        requestOption
      )
        .then((res) => res.json())
        .then((res) => {       
          setPurchasedata(res.data);
          setPageDataSize(res.data.length);
          setTotalemployee(res.total);
          setPageCount(Math.ceil(res.total / PageSize));
        })
        .catch((error) => console.error("ERROR:", error));
    }
  };

  const serachBySelect = () => {
    fetchpurchasedata();
  };

  const clearSerachBySelect = () => {
    setBranch({});
    setFromDate(oneMonthAgo);
    setToDate(new Date());
    setDepartment({});
    fetchpurchasedata();
  };

  return (
    <div>
      {userType === "Admin" ||
      userType === "admin" ||
      userType === "Vendor" ||
      userType === "vendor" ||
      userType === "Manager" ||
      userType === "Approver" ||
      userType === "approver" ||
      userType === "FM" ? (
        <div id="form1">
          <div className="leftbar-tab-menu">
            {/* TOP BAR START */}
            <Topbar
              isMenuOpen={isMenuOpen}
              setIsMenuOpen={setIsMenuOpen}
              pagewrap={pagewrap}
              setPageWrap={setPageWrap}
            />
            {/* TOP BAR END */}

            {/* SIDE BAR START */}
            <Sidebar />
            {/* SIDE BAR END */}

            {/* NAV_BAR START */}
            <Navbar isMenuOpen={isMenuOpen.style} />
            {/* NAV_BAR END */}
          </div>
          {viewdetails ? (
            <div className={`page-wrapper ${pagewrap.style}`}>
              <div className="page-content-tab">
                <div className="container-fluid porder-cont">
                  <div className="pagecontent">
                    <div className="row porder-summary">
                      <span className="page-table-title">PURCHASE ORDERS</span>

                      {/* SELECT FILTER BY VALUE START */}
                      <span className="seleting-menus">
                        <div className="porder-sea">
                          <div className="input-group porder-input">
                            <i className="fa fa-search"></i>
                            <input
                              type="search"
                              placeholder="Doc Entry / Vendor Name"
                              aria-describedby="button-addon3"
                              className="form-control bg-none border-0 porder-input12"
                              onChange={(e) => setSearchval(e.target.value)}
                            />
                          </div>
                        </div>

                        <span className="input-group from-to-date-picker d-flex">
                          <span
                            className="input-group-text select"
                            id="from-label"
                          >
                            From
                          </span>
                          <div>
                            <DatePicker
                              selected={fromDate}
                              // selectsStart
                              className="select from-date"
                              dateFormat="dd/MM/yyyy"
                              startDate={fromDate}
                              endDate={toDate}
                              onChange={(date) => setFromDate(date)}
                              maxDate={new Date()}
                            />
                          </div>
                          <span
                            className="input-group-text select"
                            id="to-label"
                          >
                            to
                          </span>
                          <div>
                            <DatePicker
                              selected={toDate}
                              // selectsEnd
                              className="select from-date"
                              dateFormat="dd/MM/yyyy"
                              startDate={fromDate}
                              endDate={toDate}
                              minDate={fromDate}
                              onChange={(date) => setToDate(date)}
                              maxDate={new Date()}
                            />
                          </div>
                        </span>

                        <Form.Select
                          aria-label="Default select example"
                          name="branch"
                          className="form-control select"
                          value={branch}
                          onChange={(event) => setBranch(event.target.value)}
                        >
                          <option className="option-label">Branch</option>
                          {branchnamecode.map((br, i) => {
                            return (
                              <option key={i} value={br.code}>
                                {br.code}
                              </option>
                            );
                          })}
                        </Form.Select>

                        <button
                          type="button"
                          className="btn btn-select-search d-flex"
                          onClick={serachBySelect}
                        >
                          <span className="btn-inline-item d-flex">
                            <i className="bi bi-search"> </i>
                            <h6>Search</h6>
                          </span>
                        </button>
                        <button
                          type="button"
                          className="btn btn-clear-select"
                          onClick={clearSerachBySelect}
                        >
                          <i className="bi bi-x-circle-fill"></i>
                        </button>
                      </span>
                      {/* SELECT FILTER BY VALUE END */}
                    </div>
                    <div className="row purchse-order">
                      <div className="table table-responsive table-wrapper-scroll-x my-custom-scrollbar porder-scroll">
                        <table className="table porder-table">
                          <thead>
                            <tr>
                              <th className="po-thead">Po.DocEntry</th>
                              <th className="po-thead">Doc.No</th>
                              <th className="po-thead">Date</th>
                              {/* <th className="po-thead">Due.Date</th> */}
                              <th className="po-thead">Vendor Ref.No</th>
                              <th
                                className="po-thead"
                                style={{
                                  display:
                                    userType !== "vendor"
                                      ? "table-cell"
                                      : "none",
                                }}
                              >
                                Vendor Name
                              </th>
                              {/* <th className="po-thead">Branch</th> */}
                              <th className="po-thead">Ship.To </th>
                              <th className="po-thead">Bill.To </th>
                              {/* <th className="po-thead">Line Total </th> */}
                              <th className="po-thead">
                                Tax Amount&nbsp;(&#8377;)
                              </th>
                              <th className="po-thead">Doc.Total</th>
                                {/* <th className="po-thead">Payment Days</th> */}
                              <th className="po-thead">Remarks</th>
                              <th className="po-thead">Details</th>
                            </tr>
                          </thead>
                          <tbody>
                            {userType !== "vendor" && purchasedata !== "" ? (
                              purchasedata.map((pdata, i) => (
                                <tr key={i}>
                                  <td className="po-tbody">
                                    <span
                                      className="porder-act"
                                      onClick={(e) =>
                                        viewComponent(pdata?.pk_po_DocEntry)
                                      }
                                    >
                                      {pdata?.pk_po_DocEntry}
                                    </span>
                                  </td>
                                  <td className="po-tbody">
                                    {pdata?.po_Docnum}
                                  </td>
                                  <td className="po-tbody">
                                    {moment(pdata?.po_date).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </td>
                                  {/* <td className="po-tbody">
                                      {moment(pdata?.po_DueDate).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </td> */}
                                  <td className="po-tbody">
                                    {pdata?.vendor_ref_no}
                                  </td>

                                  <td key={i} className="po-tbody">
                                    {pdata?.vendor_name}
                                  </td>
                                  {/* <td className="po-tbody">
                                      {pdata?.branch_name}
                                    </td> */}
                                  <td
                                    className="po-tbody"
                                    style={{ maxWidth: "200px" }}
                                  >
                                    {pdata?.shipto}
                                  </td>
                                  <td
                                    className="po-tbody"
                                    style={{ maxWidth: "200px" }}
                                  >
                                    {pdata?.billto}
                                  </td>
                                  {/* <td className="po-tbody">
                                      {pdata?.line_total}
                                    </td> */}
                                  <td className="po-tbody">
                                    {pdata?.tax_amount}
                                  </td>
                                  <td className="po-tbody">
                                    {pdata?.doc_total}
                                  </td>
                                  {/* <td className="po-tbody">
                                      {pdata?.payment_days}
                                    </td> */}

                                  <td
                                    className="po-tbody"
                                    style={{ maxWidth: "200px" }}
                                  >
                                    {pdata?.remarks}
                                  </td>
                                  <td className="po-tbody">
                                    <button
                                      className="btn-secondary porder-act"
                                      onClick={(e) =>
                                        viewComponent(pdata?.pk_po_DocEntry)
                                      }
                                    >
                                      <i className="bi bi-eye-fill" />
                                      View
                                    </button>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <>
                                {purchasedata !== "" ||
                                purchasedata !== null ? (
                                  purchasedata
                                    .filter(
                                      (prc) =>
                                        prc?.vendor_ref_no
                                          ?.toLowerCase()
                                          ?.includes(searchval) ||
                                        prc?.pk_po_DocEntry?.includes(searchval)
                                    )
                                    .map((pvdata) => (
                                      <tr>
                                        <td className="po-tbody">
                                          <span
                                            className="porder-act"
                                            onClick={(e) =>
                                              viewComponent(
                                                pvdata?.pk_po_DocEntry
                                              )
                                            }
                                          >
                                            {pvdata?.pk_po_DocEntry}
                                          </span>
                                        </td>
                                        <td className="po-tbody">
                                          {pvdata?.po_Docnum}
                                        </td>
                                        <td className="po-tbody">
                                          {moment(pvdata?.po_date).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </td>
                                        {/* <td className="po-tbody">
                                        {moment(pvdata?.po_DueDate).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </td> */}
                                        <td className="po-tbody">
                                          {pvdata?.vendor_ref_no}
                                        </td>

                                        <td
                                          className="po-tbody"
                                          style={{
                                            display:
                                              userType === "vendor" ||
                                              userType === "Vendor"
                                                ? "none"
                                                : "table-cell",
                                          }}
                                        >
                                          {pvdata?.vendor_name}
                                        </td>
                                        {/* <td className="po-tbody">
                                          {pvdata?.branch_name}
                                        </td> */}
                                        <td
                                          className="po-tbody"
                                          style={{ maxWidth: "200px" }}
                                        >
                                          {pvdata?.shipto}
                                        </td>
                                        <td
                                          className="po-tbody"
                                          style={{ maxWidth: "200px" }}
                                        >
                                          {pvdata?.billto}
                                        </td>
                                        {/* <td className="po-tbody">
                                        {pvdata?.line_total}
                                      </td> */}
                                        <td className="po-tbody">
                                          {pvdata?.tax_amount}
                                        </td>
                                        <td className="po-tbody">
                                          {pvdata?.doc_total}
                                        </td>
                                        {/* <td className="po-tbody">
                                        {pvdata?.payment_days}
                                      </td> */}
                                        <td
                                          className="po-tbody"
                                          style={{ maxWidth: "200px" }}
                                        >
                                          {pvdata?.remarks}
                                        </td>
                                        <td className="po-tbody">
                                          <button
                                            className="btn-secondary porder-act"
                                            onClick={(e) =>
                                              viewComponent(
                                                pvdata?.pk_po_DocEntry
                                              )
                                            }
                                          >
                                            <i className="bi bi-eye-fill" />{" "}
                                            View
                                          </button>
                                        </td>
                                      </tr>
                                    ))
                                ) : (
                                  <span className="no-data-found">
                                    ---- No Data Found ----
                                  </span>
                                )}
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="porder-down d-flex">
                    <span className="page-range">
                      <p>{pageDataFrom}</p>-<p>{pageDataTill}</p>
                      &nbsp; of &nbsp;
                      <p>{totalemployee}</p>
                    </span>
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel={<i className="fa fa-angle-double-right" />}
                      onPageChange={handlePageClick}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={2}
                      pageCount={pageCount}
                      previousLabel={<i className="fa fa-angle-double-left" />}
                      renderOnZeroPageCount={null}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                      disabledClassName={"disabled"}
                    />
                    <button
                      type="button"
                      className="btn btn-outline-primary float-right porder-button"
                      onClick={(e) => exportExcel(purchasedata)}
                    >
                      Download &nbsp;
                      <img src={excelimg} alt="excel" className="thumbnail" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <ViewPurchaseOrderDetails
              viewdetails={viewdetails}
              goBacktopurchaseorder={goBacktopurchaseorder}
              pagewrap={pagewrap}
              // purchasedata={purchasedata}
              viewpurchasedata={viewpurchasedata}
              fetchpurchasedata={fetchpurchasedata}
            />
          )}
        </div>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default Purchaseorder;
