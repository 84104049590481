import React, { useState } from "react";
import portea from "../images/vhd.svg";
import colplus from "../images/col-plus.png";
import whiplus from "../images/whi-plus.png";
import porteahome from "../images/897507.svg";
import { Link } from "react-router-dom";
import "../css/login.css";
import { useNavigate } from "react-router-dom";

function Login() {
  const [email, setEmail] = useState("");
  const [genOTPStatus, setGenOTPStatus] = useState(false);
  const [disablestatus, setDisablestatus] = useState(true);
  const [enteredOTP, setEnteredOTP] = useState("");
  const [vendorLogins, setVendorLogins] = useState([]);
  const [employeeLogins, setEmployeeLogins] = useState([]);
  const [loginType, setLoginType] = useState({});
  const [modelShow, setModelShow] = useState(false);
  const [loginMessage, setLoginMessage] = useState("");
  const [verifyEmail, setVerifyEmail] = useState("");
  let navigate = useNavigate();

  const navigateToDashboard = () => {
    setTimeout(() => navigate("/dashboard"), 2000);
  };

  const loginvalidation = (e) => {
    if (
      vendorLogins.some(
        (ven) => ven.otp == enteredOTP && ven.email === email
      ) ||
      employeeLogins.some((emp) => emp.otp == enteredOTP && emp.email === email)
    ) {
      setModelShow(true);
      setLoginMessage("Login Successfull");
      if (loginType?.role === "Admin" || loginType?.role === "admin") {
        sessionStorage.setItem("loginType", loginType?.role);
        sessionStorage.setItem("login-code", loginType?.pk_emp_code);
        sessionStorage.setItem("login-name", loginType?.emp_name);
        sessionStorage.setItem("email", email);
        navigateToDashboard();
      } else if (loginType?.role === "Vendor" || loginType?.role === "vendor") {
        sessionStorage.setItem("loginType", loginType?.role);
        sessionStorage.setItem("login-code", loginType?.vendor_code);
        sessionStorage.setItem("login-name", loginType?.vendor_name);
        sessionStorage.setItem("email", email);
        navigateToDashboard();
      } else {
        if (
          loginType?.job_title === "Approver" ||
          loginType?.job_title === "approver"
        ) {
          sessionStorage.setItem("loginType", loginType?.job_title);
        } else {
          sessionStorage.setItem("loginType", loginType?.role);
        }
        sessionStorage.setItem("login-code", loginType?.pk_emp_code);
        sessionStorage.setItem("login-name", loginType?.emp_name);
        sessionStorage.setItem("email", email);
        navigateToDashboard();
      }
    } else {
      setModelShow(false);
      setLoginMessage("Invalid OTP, Please enter valid OTP");
    }
    e.preventDefault();
  };

  const checkOTP = () => {
    if (email !== "" && email !== null) {
      const emailData = {
        email: email,
      };      
      let requestoption = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          // Accept: "application/json",
        },
        body: JSON.stringify(emailData),
      };
      fetch(
        "https://portea.betaphase.in/loginjson/login_users/add_data",
        requestoption
      )
        .then((res) => res.json())
        .then((res) => {          
          if (res.status == 100) {
            setGenOTPStatus(true);
            setDisablestatus(false);
            setLoginType(res?.data);
            setVerifyEmail(res?.message);
          } else {
            setGenOTPStatus(false);
            setDisablestatus(true);
            setVerifyEmail(res?.message);
          }
          fetch(
            "https://portea.betaphase.in/vendorjsonmatching_vendor_and_branch/fetching_branch_name/"
          )
            .then((res) => res.json())
            .then((res) => {            
              setVendorLogins(res.filter((v) => v.email));
            });
          fetch("https://portea.betaphase.in/employee_masterjson/get_data")
            .then((res) => res.json())
            .then((res) => {              
              setEmployeeLogins(res?.data.filter((e) => e.email));
            });
        });
    } else {
      setVerifyEmail("Not a valid email");
    }
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row my-auto">
          <div className="col-md-6 login-left">
            <div className="row logo">
              <div className="col-md-3">
                <img src={portea} className="thumbnail top-logo" alt="logo" />
              </div>
              <div className="col-md-9"></div>
            </div>
            <div className="row my-form">
              <div className="col-md-3"></div>
              <div className="col-md-6">
                <p className="wel">Welcome to VENDOR Help Desk</p>
                <h3 className="login-text">Login to your Account </h3>

                <form onSubmit={loginvalidation}>
                  <div className="mb-4 login-with-otp">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label email"
                    >
                      Username
                    </label>
                    <input
                      type="email"
                      className="form-control login"
                      id="email"
                      placeholder="Enter email/username"
                      name="email"
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />

                    <button
                      className="btn btn-warning otp-button"
                      type="button"
                      onClick={checkOTP}
                      disabled={genOTPStatus}
                    >
                      Generate OTP
                    </button>
                    {verifyEmail ? (
                      <p className="email-verify-msg">
                        {verifyEmail}, Please check your email.
                      </p>
                    ) : (
                      ""
                    )}
                  </div>

                  <div
                    className="otp-login-failed-message"
                    disabled={disablestatus}
                  >
                    <div className="mb-4 pass-box">
                      <label
                        htmlFor="exampleInputPassword1"
                        className="form-label password"
                      >
                        Enter OTP
                      </label>
                      <input
                        type="password"
                        className="form-control login"
                        id="password"
                        placeholder="Enter OTP"
                        name="pswd"
                        onChange={(e) => setEnteredOTP(e.target.value)}
                        required
                      />
                    </div>

                    <div className="d-grid gap-2 login-btn">
                      <button
                        className="btn btn-outline-primary login-btn"
                        type="submit"
                      >
                        Log in
                      </button>
                    </div>
                  </div>
                </form>
                {modelShow === false ? (
                  <span className="login-status-msg fails">{loginMessage}</span>
                ) : modelShow === true ? (
                  <span className="login-status-msg success">
                    {loginMessage}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-3"></div>
            </div>
            <div className="row icon">
              <div className="col-md-3">
                <img
                  src={colplus}
                  alt="col-plus"
                  className="img-rounded myround"
                />
              </div>
              <div className="col-md-9"></div>
            </div>
          </div>
          <div className="col-md-6 right-side">
            <div className="row icon1">
              <div className="col-md-9"></div>
              <div className="col-md-3">
                <img
                  src={whiplus}
                  alt="whi-plus"
                  className="img-rounded right-round"
                />
              </div>
            </div>
            <img
              src={porteahome}
              className="rounded float-end logo-end"
              alt="logo"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
