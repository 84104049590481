import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useHistory } from "react-router-dom";
import "../css/view.css";
import Purchaseorder from "./Purchaseorder";
import moment from "moment";
import { ProgressBar } from "react-bootstrap";
import AlertMessageModel from "./AlertMessageModel";
import { requestOptionsGet } from "./requestOptionHeaders";
import { baseURL } from "./BaseUrl";

function ViewPurchaseOrderDetails(props) {
  const [purchaseorderdata, setPurchaseorderdata] = useState([]);
  const [purchaseorderitems, setPurchaseorderitems] = useState([]);
  const [invoiceUploadModel, setInvoiceUploadModel] = useState(false);
  const [poDocNum, setPoDocNum] = useState("");
  const [progress, setProgress] = useState("");
  const [file, setFile] = useState("");
  const [qtyAlertMsg, setQtyAlertMsg] = useState([]);
  const [editingQnty, setEditingQnty] = useState([]);
  const [modelShow, setModelShow] = useState(false);
  const [submitMessage, setSubmitMessage] = useState("");
  const [submitSuccessMessage, setSubmitSuccessMessage] = useState("");
  const [responseStatus, setResponseStatus] = useState("");

  const handleAlertClose = () => setModelShow(false);

  const alertClosesuto = () => {
    setTimeout(() => setModelShow(false), 2000);
  };
  const hiddenFileInput = useRef(null);

  const uploadClick = () => {
    hiddenFileInput.current.click();
  };

  const userType = sessionStorage.getItem("loginType");

  // ***** PDF TO BASE64 CONVERTING START **** //
  const fileToBase64 = (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(null, reader.result);
    };
    reader.onerror = function (error) {
      cb(error, null);
    };
  };

  const handleChange = ({ target }) => {
    if (target.files < 1 || !target.validity.valid) {
      return;
    }
    fileToBase64(target.files[0], (err, result) => {
      if (result) {
        setFile(result.toString().replace(/^data:(.*,)?/, ""));
        setProgress(Math.round((100 * 50) / 50));
      }
    });
  };
  // ***** PDF TO BASE64 CONVERTING END **** //

  const purchaseId = props.viewpurchasedata;
  const typeOfview = "poDocNum";
  // let navigate = useNavigate();
  // const history = useHistory();
  useEffect(() => {
    fetchpurchaseiddata();
    fetchpurchaseorderlist();
  }, [purchaseId]);

  const fetchpurchaseiddata = () => {
    let requestOption = requestOptionsGet;
    fetch(
      `${baseURL}/purchase_orderjson/get_selected_item?pk_po_DocEntry=${purchaseId}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {      
        setPurchaseorderdata(res[0]);
      })
      .catch((error) => console.error("ERROR:", error));
  };

  const fetchpurchaseorderlist = () => {
    let requestOption = requestOptionsGet;
    fetch(
      `${baseURL}/po_line_itemsjson/get_selected_item?fk_po_DocEntry=${purchaseId}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {        
        setPurchaseorderitems(res.data);
      })
      .catch((error) => console.error("ERROR:", error));
  };
  const [lineNumber, setLineNumber] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);

  const setQuantity = (e, index) => {
    if (e.target.value <= e.target.selected) {
      const { name, value } = e.target;
      const list = [...editingQnty];
      list[index][name] = value;
      editingQnty[index].lineNo = e.target.id;
      setEditingQnty(list);
      setQtyAlertMsg({ status: false, message: "" });
    } else {
      const { name, value } = e.target;
      const list = [...editingQnty];
      list[index][name] = e.target.selected;
      e.target.value = "";
      editingQnty[index].lineNo = e.target.id;
      setEditingQnty(list);
      setQtyAlertMsg({
        status: true,
        id: e.target.id,
        message: "Enter Valid Quantity",
      });
    }
  };


  useEffect(() => {
    let selectItemArray = [];
    for (var i = 0; i < purchaseorderitems.length; i++) {
      for (var j = 0; j < lineNumber.length; j++) {
        for (var k = 0; k < editingQnty.length; k++) {
          if (
            purchaseorderitems[i].LineNum === lineNumber[j] &&
            editingQnty[k].lineNo === lineNumber[j]
          ) {
            purchaseorderitems[i].selectStatus = "true";
            selectItemArray.push({
              id: purchaseorderitems[i].LineNum,
              ItemCode: purchaseorderitems[i].ItemCode,
              Quantity: purchaseorderitems[i].Qty,
              fulfilled: JSON.parse(editingQnty[k].qtyValue),
              remaining_qty:
                purchaseorderitems[i].Qty - editingQnty[k].qtyValue,
              Price: purchaseorderitems[i].UnitRate,
              Currency: purchaseorderitems[i].Currency,
              DiscountPercent: purchaseorderitems[i].Discount,
              WarehouseCode: purchaseorderitems[i].fk_whs_code,
              UoMCode: purchaseorderitems[i].UOM,
              BaseEntry: purchaseorderitems[i].fk_po_DocEntry,
              BaseLine: purchaseorderitems[i].LineNum,
              BaseType: "22",
            });
          }
        }
      }
    }
    setSelectedItem(selectItemArray);
  }, [lineNumber, purchaseorderitems, editingQnty]);


  const setLineItemData = (e, index) => {
    if (e.target.checked) {
      setLineNumber([...lineNumber, e.target.value]);
      setEditingQnty([
        ...editingQnty,
        {
          lineNo: e.target.value,
          qtyValue: e.target.selected,
        },
      ]);
    } else {
      const list = [...editingQnty];
      list.splice(index, 1);
      setEditingQnty(list);
      setLineNumber(lineNumber.filter((value) => value !== e.target.value));
    }
  };

  let isValidate = lineNumber.length !== 0 && file !== "";


  const UploadInvoiceforApproval = (e) => {
    e.preventDefault();
    const draftdatasuccess = {
      CardCode: purchaseorderdata.fk_vendor_code,
      DocDate: purchaseorderdata.po_date,
      DocDueDate: purchaseorderdata.po_DueDate,
      DocObjectCode: "oPurchaseInvoices",
      DocType: "dDocument_Items",
      ShipToCode: purchaseorderdata.shipto,
      PayToCode: purchaseorderdata.billto,
      BPL_IDAssignedToInvoice: purchaseorderdata.fk_branch,
      NumAtCard: purchaseorderdata.vendor_ref_no,
      U_IKVHDID: "test",
      Attachment: file,
      dept_status: "Pending",
      inv_status: "Pending",
      DocumentLines: selectedItem,
    };


    let statusrequestoption = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(draftdatasuccess),
    };
    fetch(`${baseURL}/invoice_savejson/items/`, statusrequestoption)
      .then((res) => res.json())
      .then((res) => {

        if (res?.status === 100) {
          setResponseStatus(res.status);
          setModelShow(!modelShow);
          setSubmitSuccessMessage("Created");
          alertClosesuto();
          setFile("");
          setProgress("");
        } else if (res?.status === "Failed") {
          setModelShow(!modelShow);
          setSubmitMessage(res.errorMessage);
          alertClosesuto();
          setFile("");
          setProgress("");
        }
      })
      .catch((error) => console.error("ERROR:", error));    
  };

  return (
    <div>
      {!props.viewdetails ? (
        <div className={`page-wrapper ${props.pagewrap.style}`}>
          <div className="page-content-tab">
            <div className="container-fluid porder-cont-view">
              <div className="pagecontent">
                <div className="row view-summary">
                  <div className="col-md-12 main-heading">
                    <span className="back">
                      <button
                        id="go-back"
                        className="btn view-back"
                        onClick={props.goBacktopurchaseorder}
                        // onClick={() => navigate(-1)}
                        // onClick={history.goBack()}
                      >
                        <i className="arrow left"></i>
                        <span>Back</span>
                      </button>
                    </span>

                    <span className="line">
                      <div className="pur-total">PURCHASE ORDER LIST</div>
                    </span>
                  </div>
                </div>
                <div className="row view-details">
                  <div className="col-md-12">
                    <div className="card view-card-body">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="row view1">
                              <div className="col-1"></div>
                              <div className="col-5">
                                <span className="view-head">Po.DocEntry</span>
                              </div>
                              <div className="col-6">
                                <span className="view-body">
                                  :&nbsp;&nbsp;&nbsp;&nbsp;
                                  {purchaseorderdata?.pk_po_DocEntry}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6 right-details">
                            <div className="row view1">
                              <div className="col-1"></div>
                              <div className="col-5">
                                {/* <span className="view-head">Due Date</span> */}
                              </div>
                              <div className="col-6">
                                {/* <span className="view-body">
                                  :&nbsp;&nbsp;&nbsp;&nbsp;
                                  {moment(purchaseorderdata?.po_DueDate).format(
                                    "DD/MM/YYYY"
                                  )}
                                </span> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="row view1">
                              <div className="col-1"></div>
                              <div className="col-5">
                                <span className="view-head">Doc.Noc</span>
                              </div>
                              <div className="col-6">
                                <span className="view-body">
                                  :&nbsp;&nbsp;&nbsp;&nbsp;
                                  {purchaseorderdata?.po_Docnum}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 right-details">
                            <div className="row view1">
                              <div className="col-1"></div>
                              <div className="col-5">
                                <span className="view-head">
                                  Tax Amount(&#8377;)
                                </span>
                              </div>
                              <div className="col-6">
                                <span className="view-body">
                                  :&nbsp;&nbsp;&nbsp;&nbsp;
                                  {purchaseorderdata?.tax_amount}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="row view1">
                              <div className="col-1"></div>
                              <div className="col-5">
                                <span className="view-head">Date</span>
                              </div>
                              <div className="col-6">
                                <span className="view-body">
                                  :&nbsp;&nbsp;&nbsp;&nbsp;
                                  {moment(purchaseorderdata?.po_date).format(
                                    "DD/MM/YYYY"
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 right-details">
                            <div className="row view1">
                              <div className="col-1"></div>
                              <div className="col-5">
                                <span className="view-head">Doc.Total</span>
                              </div>
                              <div className="col-6">
                                <span className="view-body">
                                  :&nbsp;&nbsp;&nbsp;&nbsp;
                                  {purchaseorderdata?.doc_total}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            <div className="row view1">
                              <div className="col-1"></div>
                              <div className="col-5">
                                <span className="view-head">Vendor.Ref.No</span>
                              </div>
                              <div className="col-6">
                                <span className="view-body">
                                  :&nbsp;&nbsp;&nbsp;&nbsp;
                                  {purchaseorderdata?.vendor_ref_no}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 right-details">
                            <div className="row view1">
                              <div className="col-1"></div>
                              <div className="col-5">
                                <span className="view-head">Branch</span>
                              </div>
                              <div className="col-6">
                                <span className="view-body">
                                  :&nbsp;&nbsp;&nbsp;&nbsp;
                                  {purchaseorderdata?.branch_name}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6"></div>
                          <div className="col-md-6 right-details"></div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="row view1">
                              <div className="col-1"></div>
                              <div className="col-5">
                                <span className="view-head">Ship.To</span>
                              </div>
                              <div className="col-6">
                                <span className="view-body">
                                  :&nbsp;&nbsp;&nbsp;&nbsp;
                                  {purchaseorderdata?.shipto}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 right-details">
                            <div className="row view1">
                              <div className="col-1"></div>
                              <div className="col-5">
                                <span className="view-head">Bill.To</span>
                              </div>
                              <div className="col-6">
                                <span className="view-body">
                                  :&nbsp;&nbsp;&nbsp;&nbsp;
                                  {purchaseorderdata?.billto}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row viewpage">
                  <div className="table-wrapper-scroll-x my-custom-scrollbar viewpage-scroll">
                    <table className="table view-table">
                      <thead className="views-thead">
                        <tr>
                          <th className="view-thead">Line No.</th>
                          {/* <th className="view-thead">Item Code</th> */}
                          <th className="view-thead">Item Name</th>
                          <th className="view-thead">Quantity</th>
                          <th className="view-thead">Discount %</th>
                          <th className="view-thead">Unit Rate</th>
                          <th className="view-thead">Line Total</th>
                          <th className="view-thead">Tax Code</th>
                          <th className="view-thead">Tax%</th>
                          <th className="view-thead">Tax Amount</th>
                          <th className="view-thead">Gross Total</th>
                          <th className="view-thead">UOM</th>
                          <th className="view-thead">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {purchaseorderitems !== "" ||
                        purchaseorderitems !== null
                          ? purchaseorderitems.map((item, index) => (
                              <tr key={index}>
                                <td className="view-tbody">
                                  {userType === "Vendor" ||
                                  userType === "vendor" ? (
                                    <input
                                      type="checkbox"
                                      id="invoice-lineitem-checkbox"
                                      selected={item?.Qty}
                                      name={item?.ItemCode}
                                      disabled={
                                        item?.status === "created" ||
                                        item?.status === "Created"
                                          ? true
                                          : false
                                      }
                                      value={item?.LineNum}
                                      onClick={(e) => {
                                        setLineItemData(e, index);
                                      }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                  {item?.LineNum}
                                </td>
                                {/* <td className="view-tbody">{item?.ItemCode}</td> */}
                                <td
                                  className="view-tbody"
                                  style={{ maxWidth: "100px" }}
                                >
                                  {item?.ItemName}
                                </td>
                                {item?.selectStatus ? (
                                  <td className="view-tbody">
                                    {item?.selectStatus === "true" && (
                                      <>
                                        <input
                                          type="text"
                                          className="input-qty-edit"
                                          placeholder={item?.Qty}
                                          selected={item?.Qty}
                                          name="qtyValue"
                                          id={item?.LineNum}
                                          onChange={(e) =>
                                            setQuantity(e, index)
                                          }
                                        />

                                        <div className="wrong-qty-msg">
                                          {item?.LineNum == qtyAlertMsg.id &&
                                          qtyAlertMsg.status
                                            ? qtyAlertMsg.message
                                            : ""}
                                        </div>
                                      </>
                                    )}
                                  </td>
                                ) : (
                                  <td className="view-tbody">{item?.Qty}</td>
                                )}
                                <td className="view-tbody">
                                  {item?.Discount}%
                                </td>
                                <td className="view-tbody">{item?.UnitRate}</td>
                                <td className="view-tbody">
                                  {item?.Linetotal}
                                </td>
                                <td className="view-tbody">{item?.TaxCode}</td>
                                <td className="view-tbody">
                                  <b>{item?.Tax}%</b>
                                </td>
                                <td className="view-tbody">
                                  <b>{item?.TaxAmount}</b>
                                </td>
                                <td className="view-tbody">
                                  <b>{item?.GrossTotal}</b>
                                </td>
                                <td className="view-tbody">
                                  <b>{item?.UOM}</b>
                                </td>
                                <td className="view-tbody">
                                  {item?.po_status}
                                </td>
                              </tr>
                            ))
                          : "NO DATA FOUND"}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="pro-bar-div">
                  {progress && (
                    <ProgressBar
                      now={progress}
                      label={"File Uploaded"}
                      className="pro-bar"
                    />
                  )}
                </div>
                {userType === "Vendor" || userType === "vendor" ? (
                  <div className="invoice-save-btn">
                    <div className="d-flex up-btn">
                      <button
                        type="button"
                        className="btn btn-sec upload"
                        onClick={uploadClick}
                      >
                        <i className="fa fa-upload" /> Upload PDF
                      </button>
                      <input
                        id="getFile"
                        type="file"
                        name="filetobase64"
                        accept="image/pdf"
                        // accept="application/pdf"
                        className="btn-primary upload"
                        ref={hiddenFileInput}
                        onChange={handleChange}
                        style={{ display: "none" }}
                      />
                    </div>

                    <span className="view inv-upload-btn">
                      <button
                        disabled={!isValidate}
                        id="view-invoice"
                        type="button"
                        className="btn btn-outline-primary upload-pdf"
                        onClick={(e) => UploadInvoiceforApproval(e)}
                      >
                        Save Invoice &nbsp;&nbsp;
                        <i className="fa fa-upload"></i>
                      </button>
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Purchaseorder />
      )}
      <AlertMessageModel
        modelShow={modelShow}
        alertMessage={submitMessage}
        submitSuccessMessage={submitSuccessMessage}
        responseStatus={responseStatus}
        handleAlertClose={handleAlertClose}
      />
    </div>
  );
}

export default ViewPurchaseOrderDetails;
